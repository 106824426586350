import * as React from "react";
import "../Contact/Contact.css"
import twitter from "../../Asset/Images/product/twitter.jpg";
import linkedin from "../../Asset/Images/product/linkedin.jpg";
import fb from "../../Asset/Images/product/fb.jpg"
import { Link } from "react-router-dom";
const Contact = () => {

    return (
        <div className="newcontact">
            <div className="container-fluid mb-6">
                <div className="row">
                    <div className="col-10 mx-auto">
                        <div className="row">
                            <div className="col-md-10 col-10 mx-auto">

                                <div className="contact">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="contact_left">
                                    <h5>contact@kushmanda.tech</h5>
                                </div>
                                <div className="contact">
                                    <h3>Reach Us At</h3>


                                </div>
                                <div className="c">
                                    <div className="contact_middle">
                                        <Link to={'//mobile.twitter.com/kushmandat'}
                                            target="_blank">
                                            <img className="download_image" src={twitter} alt="goo" />
                                        </Link>
                                    </div>
                                    <div className="contact_middle">
                                        <Link to={'//www.linkedin.com/company/kushmanda-tech/?originalSubdomain=in'} target="_blank">
                                            <img className="download_image" src={linkedin} alt="goo" />
                                        </Link>
                                    </div>
                                    <div className="contact_middle">
                                        <Link to={'//www.facebook.com/Kushmanda-Tech-105710125511224'} target="_blank">
                                            <img className="download_image" src={fb} alt="goo" />
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact;
