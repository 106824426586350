import * as React from "react";
import "../About/About.css"
const About = () => {

    return (

        <>
            <div className="container-fluid mb-6">
                <div className="row">
                    <div className="col-10 mx-auto">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-10 mx-auto">
                                <div className="pt-5 pt-lg-8  about_1">
                                    <h1>About Us</h1>
                                </div>
                                <div className="about">
                                    <p>According to a legend, Goddess Kushmanda once triggered the creation process with a simple smile.
                                        Material and energy manifested themselves into this beautiful world.</p>
                                    <p>Learning from her, we have put together a team and a dream to develop products that bring joy, happiness, and success to millions and millions of users.</p>
                                    <p>At Kushmanda, art, technology, and innovation are the core ingredients of each product.We work hard to raise the bar on UX and the value of each product.</p>
                                    <p>We love what we do and strive to build a better place for our future generations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>























        // <div className="about_middle">
        //     <div className="about_1">
        //         <h1>About Us</h1>
        //     </div>
        //     <div className="about_left">
        //         <img src={logo} alt="logo" />
        //         <div className="about_logo">
        //             <img src={logoname} alt="logoname" />
        //         </div>
        //     </div>
        //     <div className="content">
        //         <p>According to a legend, Goddess Kushmanda once triggered the creation process with a simple smile.
        //             Material and energy manifested themselves into this beautiful world.
        //             Learning from her, we are putting together a team and a dream to develop products that bring joy, happiness, and success to millions and millions of users.
        //             At Kushmanda, art, technology, and innovation are the core ingredients of each product.We work hard to raise the bar on UX and the value of each product.
        //             We love what we do and strive to build a better place for our future generations.</p>
        // {/* <p>According to legends goddess Kushmanda once triggered the creation process with a simple smile.

        //     Material and energy manifested themselves into this beautiful world.

        //     Learning from her we are putting together team & dreams to develop products that bring joy, happiness & success to millions and millions of users.

        //     At kushmanda art, technology & innovation are considered as core ingredients of each product.

        //     We work hard to raise bar of UX and value in each product.

        //     We love what we do & work becomes joy.
        // </p> */}
        // {/* </div>
        //     </div> */}






    )
}
export default About; 