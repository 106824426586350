import * as React from "react";
import { NavLink } from 'react-router-dom';
import mld from "../../Asset/Images/work/mld.jpg";
import wbs from "../../Asset/Images/work/wbs.jpg";
import wbsapp from '../../Asset/Images/work/wbsapp.webp';
import "../Work/Work.css"
import "../Product/Product.css"
const Work = () => {
    return (
        <>
            <div className="container-fluid mb-6">
                <div className="row">
                    <div className="col-10 mx-auto">
                        <div className="row">
                            <div className="col-md-10 col-10 mx-auto">
                                <div className="work_middle">
                                    <div className="work_1">
                                        <h1 className="our_product">Our Product</h1>
                                    </div>
                                    <div className="order-1 order-lg-1 work_media">
                                        <div className="work">
                                            <div className="work_left">

                                                {/* <img className="img-fluid img123" src={wbs} alt="1" /> */}
                                                <img className="img-fluid" src={wbs} alt="1" />
                                            </div>
                                        </div>
                                        <div className="order-2 order-lg-2 ">
                                            <div className="workDetail">
                                                <div className="work_middle">
                                                    <h2 className="work_title">wikibedtimestories.com</h2>
                                                </div>
                                                {/* <Link className="non-link" to={'//www.wikibedtimestories.com/'} target="_blank">
                                                            wikibedtimestories.com
                                                        </Link> */}
                                                <div className="work_desc">
                                                    <p className="aboutdotcom">
                                                        <a
                                                            className='non-link'
                                                            href='https://wikibedtimestories.com/'
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            wikibedtimestories.com
                                                        </a>

                                                        {/* <NavLink className='non-link' to={{ pathname: 'https://wikibedtimestories.com/' }} target="_blank">

                                                            wikibedtimestories.com
                                                        </NavLink> */}


                                                        provides bedtime stories for kids. The aim is to improve the bedtime storytelling experience and quality for at least 1% of global kids from all countries, cultures and background.</p>

                                                    <NavLink to="/products/web-wiki-bedtime-stories" className="btn1">Learn More</NavLink>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-1 order-lg-1 work_media">
                                        <div className="work">
                                            <div className="work_left">

                                                <img className="img-fluid" src={mld} alt="1" />
                                                {/* <img className="img-fluid" src={image2} alt="1" /> */}
                                            </div>
                                        </div>
                                        <div className="workDetail">
                                            <div className="work_middle">
                                                <h2 className="work_title">My Lucky Day</h2>
                                            </div>
                                            <div className="work_desc">
                                                <p>My Lucky days is world's first quantitative astrology app. We are just getting started on this journey where users can shape their own personal algorithm that predicts best results for themselves.</p>
                                                <NavLink to="/products/my-lucky-day" className="btn1">Learn More</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order-1 order-lg-1 work_media">
                                        <div className="work">
                                            <div className="work_left">

                                                {/* <img className="img-fluid img123" src={wbs} alt="1" /> */}
                                                <img className="img-fluid" src={wbsapp} alt="1" />
                                            </div>
                                        </div>
                                        <div className="order-2 order-lg-2 ">
                                            <div className="workDetail">
                                                <div className="work_middle">
                                                    <h2 className="work_title">Wiki Bedtime Stories</h2>
                                                </div>
                                                <div className="work_desc">
                                                    <p>Wiki bedtime stories started with a simple concept of providing new story to parents every day to tell their kids. Audio part is evolving now as well. Stories should come from community of story lovers. Its world's first app with intent to preserve culture of storytelling via community.</p>

                                                    <NavLink to="/products/wiki-bedtime-stories" className="btn1">Learn More</NavLink>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Work;