import React from "react";
import { NavLink } from "react-router-dom";
import "../Home/Home.css"
import home_image from "../../Asset/Images/home/home_image.png"
function Home() {
    return (
        <>
            <section id="header" className="">
                <div className="container-fluid nav_bg">
                    <div className="row">
                        <div className="col-10 mx-auto">
                            <div className="row">
                                <div className="col-md-6 pt-5 pt-lg-8 order-1 order-lg-1">
                                    <div className="nav_slogan">
                                        <h1>Kushmanda.tech</h1>
                                        <h6 className="">
                                            Products with enormous value.
                                        </h6>
                                        <div className="mt-3">
                                            <NavLink to="/products" className="btn-get-started">View Our Latest Products</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-2 order-lg-2 header-img">
                                    <img src={home_image} className="img-fluid animated" alt="home img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Home;